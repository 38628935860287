import revive_payload_client_B6AVYjzjD5 from "/app/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@22.13.4_better-sqlite3@11.8.1_db0@0.2.4_b_2dfa801400ef47e1282e2b462ff67fa3/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_DpGdQSjzBb from "/app/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@22.13.4_better-sqlite3@11.8.1_db0@0.2.4_b_2dfa801400ef47e1282e2b462ff67fa3/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_Gm8eZI2Kzb from "/app/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@22.13.4_better-sqlite3@11.8.1_db0@0.2.4_b_2dfa801400ef47e1282e2b462ff67fa3/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_PovWcEg7y1 from "/app/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@22.13.4_better-sqlite3@11.8.1_db0@0.2.4_b_2dfa801400ef47e1282e2b462ff67fa3/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_Ib1Hj1YpLc from "/app/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@22.13.4_better-sqlite3@11.8.1_db0@0.2.4_b_2dfa801400ef47e1282e2b462ff67fa3/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_TCbUfyGvRs from "/app/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@22.13.4_better-sqlite3@11.8.1_db0@0.2.4_b_2dfa801400ef47e1282e2b462ff67fa3/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_l1NOEvAxvM from "/app/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@22.13.4_better-sqlite3@11.8.1_db0@0.2.4_b_2dfa801400ef47e1282e2b462ff67fa3/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_zlvi6dcIsi from "/app/node_modules/.cache/nuxt/.nuxt/components.plugin.mjs";
import prefetch_client_Rl4VoGEaJ3 from "/app/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@22.13.4_better-sqlite3@11.8.1_db0@0.2.4_b_2dfa801400ef47e1282e2b462ff67fa3/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_client_fxr5QpR3De from "/app/node_modules/.pnpm/@nuxtjs+color-mode@3.5.2_magicast@0.3.5/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.js";
import switch_locale_path_ssr_IxCo0KjAOQ from "/app/node_modules/.pnpm/@nuxtjs+i18n@9.2.0_@vue+compiler-dom@3.5.13_eslint@9.20.1_jiti@2.4.2__magicast@0.3.5_ro_74e54ce26d64f96181d36f24d0d293c9/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.js";
import route_locale_detect_Ho2cuOcfi6 from "/app/node_modules/.pnpm/@nuxtjs+i18n@9.2.0_@vue+compiler-dom@3.5.13_eslint@9.20.1_jiti@2.4.2__magicast@0.3.5_ro_74e54ce26d64f96181d36f24d0d293c9/node_modules/@nuxtjs/i18n/dist/runtime/plugins/route-locale-detect.js";
import i18n_gkQ3lLG1Ww from "/app/node_modules/.pnpm/@nuxtjs+i18n@9.2.0_@vue+compiler-dom@3.5.13_eslint@9.20.1_jiti@2.4.2__magicast@0.3.5_ro_74e54ce26d64f96181d36f24d0d293c9/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.js";
import ssg_detect_rYFr7MWwnq from "/app/node_modules/.pnpm/@nuxtjs+i18n@9.2.0_@vue+compiler-dom@3.5.13_eslint@9.20.1_jiti@2.4.2__magicast@0.3.5_ro_74e54ce26d64f96181d36f24d0d293c9/node_modules/@nuxtjs/i18n/dist/runtime/plugins/ssg-detect.js";
export default [
  revive_payload_client_B6AVYjzjD5,
  unhead_DpGdQSjzBb,
  router_Gm8eZI2Kzb,
  payload_client_PovWcEg7y1,
  navigation_repaint_client_Ib1Hj1YpLc,
  check_outdated_build_client_TCbUfyGvRs,
  chunk_reload_client_l1NOEvAxvM,
  components_plugin_zlvi6dcIsi,
  prefetch_client_Rl4VoGEaJ3,
  plugin_client_fxr5QpR3De,
  switch_locale_path_ssr_IxCo0KjAOQ,
  route_locale_detect_Ho2cuOcfi6,
  i18n_gkQ3lLG1Ww,
  ssg_detect_rYFr7MWwnq
]